import React from 'react'
import CountUp from 'react-countup'
import Callback from '../Callback'
import Integrations from './homeComponents/Integrations';

const Home = () => {

    return (
        <div className="mainWrapper">
            {/* <!-- **************HERO SECTION STARTED***************** --> */}
            <section className="heroSection" id='home'>
                <div className="heroSectionWrapper">
                    <div className="container">
                        <div className="row heroSectionContent">
                            <div className="col-md-7">
                                <div className="leftContent animate__animated animate__fadeInUp">
                                    <h1>We bring the<b> integrated solution</b> to modern business financial problems</h1>
                                    <p>
                                        Covering entire loan journey from customer on-boarding, digitization and compression
                                        of customer&apos;s documents
                                        on a cloud storage through real time customer credit verification to loan
                                        disbursement and closure
                                        of loan application.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="rightContent wow animate__animated animate__fadeInUp">
                                    <img className="img-fluid" src={require("../../assets/images/hero-img.png")} width="" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- **************HERO SECTION ENDED***************** --> */}

            {/* <!-- **************ABOUT SECTION STARTED***************** --> */}
            <section className="aboutSection" id="about-section">
                <div className="aboutSectionWrapper">
                    <div className="container">
                        <div className="row aboutContent">
                            <div className="col-md-6">
                                <div className="aboutLeft wow animate__animated animate__fadeInUp">
                                    <img className="img-fluid" src={require("../../assets/images/about-us-img.png")} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="aboutRight wow animate__animated animate__fadeInUp">
                                    <h2>About Us</h2>
                                    <p>
                                        FINNCUB brings the integrated solution to modern business financial problems.
                                        Developed secure, flexible and seamless platform through which turnaround time
                                        from customer acquisition to disbursement is reduced drastically.
                                        Our product which is customer centric with simplified and efficient process helping the FINTECH
                                        business uplifting their financial status in long run.<br />
                                        Integrated with standard APIs, fast customer analytics and powerful reporting technology,
                                        FINNCUB helps in risk mitigation with clear visibility to boost up the business
                                        and revolutionizing the financial process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- **************ABOUT SECTION ENDED***************** --> */}

            {/* <!-- **************PRODUCT SECTION STARTED***************** --> */}
            <section className="productSection wow animate__animated animate__fadeInUp" id="product-section">
                <div className="productSectionWrapper">
                    <div className="container">
                        <div className="row productContent">
                            <h2 className="primary-heading text-center mb-5">Our Products</h2>
                            <div className="col-md-6 col-lg-4">
                                <div className="productCard wow animate__animated animate__bounceInUp animate__slow ">
                                    <div className="productCardWrapper">
                                        <div className="productCardContent">
                                            <div className="productCardFront">
                                                <img src={require('../../assets/images/product1.png')} height="120" alt="Avatar" />
                                                <p>Loan Origination System</p>
                                            </div>
                                            <div className="productCardBack">
                                                <ul>
                                                    <li>Pre-Qualification Process</li>
                                                    <li>Loan Application</li>
                                                    <li>Application Process</li>
                                                    <li>Underwriting</li>
                                                    <li>Credit Decision</li>
                                                    <li>Loan Funding</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="productCard wow animate__animated animate__bounceInUp animate__slow ">
                                    <div className="productCardWrapper">
                                        <div className="productCardContent">
                                            <div className="productCardFront">
                                                <img src={require("../../assets/images/product2.png")} height="120" alt="Avatar" />
                                                <p>Loan Management System </p>
                                            </div>
                                            <div className="productCardBack">
                                                <ul>
                                                    <li>Loan Monitoring</li>
                                                    <li>Loan Top-up</li>
                                                    <li>NPA Management</li>
                                                    <li>Debit Recovery & Collection</li>
                                                    <li>Loan Application Closure</li>
                                                    <li>Loan Write-Off</li>
                                                    <li>Death Case & Insurance Management</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="productCard wow animate__animated animate__bounceInUp animate__slow ">
                                    <div className="productCardWrapper">
                                        <div className="productCardContent">
                                            <div className="productCardFront">
                                                <img src={require("../../assets/images/product3.png")} height="120" alt="Avatar" />
                                                <p>Finance ERP</p>
                                            </div>
                                            <div className="productCardBack">
                                                <ul>
                                                    <li>Automatic Voucher posting for Loan Disbursement & Loan Collections.</li>
                                                    <li>Bank Statement Management & Automatic Transaction Reconciliation.</li>
                                                    <li>Expense Management Transaction booking module.</li>
                                                    <li>Collection Process i.e. Regular installment/Partial Payment/Pre-Closure payment availability.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="productCard wow animate__animated animate__bounceInUp animate__slow ">
                                    <div className="productCardWrapper">
                                        <div className="productCardContent">
                                            <div className="productCardFront">
                                                <img src={require("../../assets/images/product4.png")} height="120" alt="Avatar" />
                                                <p>Co-Lending</p>
                                            </div>
                                            <div className="productCardBack">
                                                <ul>
                                                    <li>Simplified Partner acquisition & Onboarding.</li>
                                                    <li>Strict adherence to partner's business policies.</li>
                                                    <li>Straight Through Processing for an end-to-end automated & paperless journey.</li>
                                                    <li>Seamless client info sharing along with docs between partners.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="productCard wow animate__animated animate__bounceInUp animate__slow ">
                                    <div className="productCardWrapper">
                                        <div className="productCardContent">
                                            <div className="productCardFront">
                                                <img src={require("../../assets/images/product5.png")} height="120" alt="Avatar" />
                                                <p>Real Time Analytics</p>
                                            </div>
                                            <div className="productCardBack">
                                                <ul>
                                                    <li>Real Time Business Reporting</li>
                                                    <li>Real Time Collection Report</li>
                                                    <li>Settlement Report</li>
                                                    <li>Business Behavioral Analysis</li>
                                                    <li>Real Time Financial Reports to overview the accounting Transactions.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="productCard wow animate__animated animate__bounceInUp animate__slow ">
                                    <div className="productCardWrapper">
                                        <div className="productCardContent">
                                            <div className="productCardFront">
                                                <img src={require("../../assets/images/product6.png")} height="120" alt="Avatar" />
                                                <p>HR Operations</p>
                                            </div>
                                            <div className="productCardBack">
                                                <ul>
                                                    <li>Seamless Employee On-boarding</li>
                                                    <li>Grant & Revoke roles of FINNCUB users</li>
                                                    <li>Employee Transfer Management</li>
                                                    <li>Employee work reports and analytics.</li>
                                                    <li>Real time Geo-Location capturing of field staff for their daily route tracking.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- **************PRODUCT SECTION ENDED***************** --> */}


            {/* <!-- **************PARTNER SECTION STARTED***************** --> */}
            <Integrations />
            {/* <!-- **************PARTNER SECTION ENDED***************** --> */}


            {/* <!-- **************ACHIEVEMENT SECTION STARTED***************** --> */}
            <section className="achievementSection wow animate__animated animate__fadeInUp">
                <div className="achievementSectionWrapper">
                    <div className="container">
                        <div className="row achievementContent">
                            <h2 className="primary-heading text-center mb-5">Our Achivements</h2>
                            <div className="col-sm-4">
                                <div className="achievementDetail">
                                    <h3>
                                        <CountUp enableScrollSpy={true} scrollSpyOnce={true} duration={1.5} end={10} /> lacs+
                                    </h3>
                                    <p>Total Customers</p>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="achievementDetail">
                                    <h3>
                                        <CountUp enableScrollSpy={true} scrollSpyOnce={true} duration={2} end={1000} /> Cr+
                                    </h3>
                                    <p>Managed Portfolio</p>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="achievementDetail border-0">
                                    <h3>
                                        <CountUp enableScrollSpy={true} scrollSpyOnce={true} decimals={1} duration={1} end={2.5} /> Cr+
                                    </h3>
                                    <p>Total Transactions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- **************ACHIEVEMENT SECTION ENDED***************** --> */}

            {/* <!-- **************CONTACT US SECTION STARTED***************** --> */}
            <Callback />
            {/* <!-- **************CONTACT US SECTION ENDED***************** --> */}

        </div>
    )
}

export default Home