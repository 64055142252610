import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {

    const firstMount = useRef(true)
    const [small, setSmall] = useState(false);
    const [mobileNav, setMobileNav] = useState(false)

    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false
            if (typeof window !== "undefined") {
                window.addEventListener("scroll", () =>
                    setSmall(window.pageYOffset > 50)
                );
            }
        }
        //eslint-disable-next-line
    }, [])

    return (
        <header >
            <Navbar className={small ? 'shrinkHeader header bg-primary-color w-100' : 'header bg-primary-color w-100'} fixed="top" expand="lg" collapseOnSelect={true}>
                <Container className='headerContainer'>
                    <Navbar.Brand className='p-0' href="#home">
                        <img src={require("../assets/images/finncub-logo.png")} alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setMobileNav(!mobileNav)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#about-section">About Us</Nav.Link>
                            <Nav.Link href="#product-section">Our Products</Nav.Link>
                            <Nav.Link href="#partner-section">Integration</Nav.Link>
                            <Nav.Link href="#contact-section">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                {/* {mobileNav && <div className='mobile-nav bg-primary-color w-100' style={{ marginTop: '20px', textAlign: 'center'}}>
                    <Nav className="ms-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#about-section">About Us</Nav.Link>
                        <Nav.Link href="#product-section">Our Products</Nav.Link>
                        <Nav.Link href="#partner-section">Integration</Nav.Link>
                        <Nav.Link href="#contact-section">Contact Us</Nav.Link>
                    </Nav>
                </div>} */}
            </Navbar>
        </header>
    )
}

export default Header