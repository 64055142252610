const host = process.env.REACT_APP_SERVER

export const contactUs = async (name, email, mobileNumber, description) => {
    const link = `${host}/ums/callback-mail`
        const response = await fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "token": localStorage.getItem('token'),
                //Accept: 'application/json'
            },
            body: JSON.stringify({ name, email, mobileNumber, description })
        })
        const json = await response.json()
        //console.log(json)
        return json
}