import React, { useState } from "react";
import { isEmail } from "../utils";
import { toast } from "react-toastify";
import { contactUs } from "../context/apis";

const Callback = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");

  const [error, setError] = useState(false);
  //const [success, setSuccess] = useState(false)

  const onMobileChange = (e) => {
    const val = e.target.value;
    if (!isNaN(val) && val.length < 11) setMobile(val);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const submitButton = async () => {
    setError(true);
    if (
      name.length < 1 ||
      !isEmail(email) ||
      mobile.length !== 10 ||
      isNaN(mobile) ||
      desc.length < 1
    ) {
      //console.log('invalid form details', name, email, mobile, desc)
      return;
    }
    const response = await contactUs(name, email, mobile, desc);
    setName("");
    setEmail("");
    setMobile("");
    setDesc("");
    //setSuccess(true)
    setError(false);
    toast.dismiss();
    toast.success("Submit successfull. We will contact you shortly");
  };

  return (
    <section className="contactSection" id="contact-section">
      <div className="contactSectionWrapper">
        <div className="container">
          <div className="row contactContent align-items-center">
            <div className="col-md-5">
              <div className="contactLeft wow animate__animated animate__fadeInUp">
                <img
                  className="img-fluid"
                  src={require("../assets/images/callback.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="contactRight wow animate__animated animate__fadeInUp">
                <h2 className="primary-heading mb-4">Request a Call Back</h2>
                <div className="row form">
                  <div className="col-12 mb-4">
                    <label htmlFor="inputName" className="form-label">
                      Name{" "}
                      <span
                        className="text-danger"
                        style={{ fontWeight: "bold" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="inputName"
                      placeholder="Enter your name"
                    />
                    {error && name.length < 1 && (
                      <small className="text-danger">
                        Please enter your name
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="inputEmail" className="form-label">
                      Email{" "}
                      <span
                        className="text-danger"
                        style={{ fontWeight: "bold" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      value={email}
                      onChange={onEmailChange}
                      type="email"
                      className="form-control"
                      id="inputEmai"
                      placeholder="Enter your email"
                    />
                    {error && !isEmail(email) && (
                      <small className="text-danger">
                        Please enter a valid email
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="inputMobile" className="form-label">
                      Mobile Number{" "}
                      <span
                        className="text-danger"
                        style={{ fontWeight: "bold" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      value={mobile}
                      onChange={onMobileChange}
                      type="tel"
                      className="form-control"
                      id="inputMobile"
                      placeholder="Enter your mobile number"
                    />
                    {error && mobile.length !== 10 && !isNaN(mobile) && (
                      <small className="text-danger">
                        Please enter a valid mobile number
                      </small>
                    )}
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="inputDescription" className="form-label">
                      Description{" "}
                      <span
                        className="text-danger"
                        style={{ fontWeight: "bold" }}
                      >
                        *
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      id="inputDescription"
                      placeholder="Enter description"
                    ></textarea>
                    {error && desc.length < 1 && (
                      <small className="text-danger">
                        Please enter a message
                      </small>
                    )}
                  </div>
                  <div className="col-md-4 mt-2">
                    <button onClick={submitButton} className="submitBtn btn">
                      Call Back
                    </button>
                  </div>
                  <div className="col-md-8 text-center">
                    {/* <>
                                            <p className='text-success mt-2 mb-0'>Submit successful!</p>
                                            <p className='text-success my-0'>We will get back to you shortly.</p>
                                        </> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Callback;
