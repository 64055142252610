import React from 'react'

const Footer = () => {
    return (
        <section className="footerSection ">
            <div className="footerSectionWrapper">
                <div className="container">
                    <div className="row footerContent">
                        <div className="col-sm-6 col-md-3">
                            <div className="footerLeft">
                                <ul className="mb-0 ps-0">
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#about-section">About us</a></li>
                                    <li><a href="#product-section">Our Products</a></li>
                                    <li><a href="#partner-section">Integration</a></li>
                                    <li><a href="#contact-section">Request a Call Back</a></li>
                                    {/* <li><a href="#0">Privacy Policy</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="footerMiddle">
                                <ul className="mb-0 ps-0">
                                    <li><b>Contact Us</b></li>
                                    <li>Email : info@sastechstudio.com</li>
                                    <li>Dial : +91 124 4679000</li>
                                    <li className="mt-4">
                                        <a className="me-3" target='_blank' rel="noreferrer" href="https://www.facebook.com/sastechstudio?mibextid=ZbWKwL"><img src={require("../assets/images/fb.png")} alt="" /></a>
                                        <a target='_blank' rel="noreferrer" href="https://www.linkedin.com/company/finncub/"><img src={require("../assets/images/linkedin.png")} alt="" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="footerRight">
                                <iframe title='SAS' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.317977346975!2d77.03832571922179!3d28.439829521579462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18695c471015%3A0xa44b7b144935cae4!2sSAS%20Tower!5e0!3m2!1sen!2sin!4v1672657027992!5m2!1sen!2sin" width="5" rel="noreferrer" height="5" frameBorder="0" style={{ border: "0" }} allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <hr className="my-0" style={{ backgroundColor: "#fff", opacity: "0.2" }} />
                    <div className="copyContent">
                        <p>© 2022 All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer