import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';
import Home from './components/home/Home';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
      <Header />
      <Home />
      <Footer />
      <ToastContainer position="top-right" autoClose={1000} newestOnTop={true} limit={4}/>
    </>
  )
}

export default App