import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Integrations = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        laptop: {
            breakpoint: { max: 1024, min: 767 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 767, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }

    return (
        <section className="partnerSection wow animate__animated animate__fadeInUp" id="partner-section">
            <div className="partnerSectionWrapper">
                <div className="container">
                    <div className="row partnerContent">
                        <h2 className="primary-heading text-center mb-5">Integration</h2>

                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            infinite={true}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop", "laptop"]}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            customTransition="transform 2000ms ease"
                            transitionDuration={2000}
                            rewind={true}
                            containerClass="carousel-container"
                        >
                            <div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner1.png")} alt="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner2.png")} alt="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner3.png")} alt="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner4.png")} alt="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner5.png")} alt="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner6.png")} alt="" />
                                </div>
                            </div><div className="col">
                                <div className="partnerImg">
                                    <img className="img-fluid" src={require("../../../assets/images/partner7.png")} alt="" />
                                </div>
                            </div>
                        </Carousel>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Integrations